import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styled from "styled-components";
import { screen, innerWidth } from "../../components/common/variables";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 80px;
  @media ${screen.small} {
    padding-top: 135px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 90px;
  }
  @media ${screen.medium} {
    padding-top: 270px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 120px;
  }

  @media ${screen.xlarge} {
    padding-top: 328px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 180px;
  }
  .success {
    max-width: ${innerWidth};
    margin: 0 auto;
    text-align: center;
    @media ${screen.small} {
      margin: 0 auto;
    }
    @media ${screen.medium} {
      margin: 0 auto;
    }
    @media ${screen.large} {
      margin: 0 auto;
    }
    &__heading {
      font-size: 1.5rem;
      font-weight: 600;
      @media ${screen.small} {
        font-size: 2.5rem;
      }
      @media ${screen.xlarge} {
        font-size: 2.7rem;
      }
    }
    &__description {
      font-size: 1rem;
      margin-top: 16px;
      @media ${screen.small} {
        font-size: 1.1rem;
      }
    }
  }
`;

const SuccessPage = () => {
  return (
    <Layout>
      <SEO title="Submitted Successfully!" />
      <Wrapper>
        <div className="success">
          <h1 className="success__heading">THANK YOU!</h1>
          <p className="success__description">
            Your form was submitted successfully.
          </p>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default SuccessPage;
